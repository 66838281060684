<template>
    <div>
      <!-- <ministry-breadcrumbs
        :mainTitle="$route.meta.title"
        :breadcrumbs="$route.meta.breadCrumb"
      /> -->
      <b-card>
        <div class="d-flex justify-content-between mb-1">
          <h3>الأدوار</h3>
          <b-button v-can="{ slug: $route.meta.slug, action: 'C' }" variant="primary" @click="$router.push({ name: 'add-role' })">
            <feather-icon icon="PlusIcon" />
            إضافة دور</b-button
          >
        </div>
        <b-card no-body class="border">
          <data-table
            ref="estatesTable"
            :fields="fields"
            ep="/roles"
            :pagination="false"
          >
            <template #cell(actions)="row">
              <div class="d-flex justify-content-end align-items-center">
                <b-button
                  variant="primary"
                  class="p-0 px-1"
                  style="height: 25px; font-size: 12px"
                  @click="
                    $router.push({
                      name: 'permissions-user',
                      params: { id: row.item.id },
                    })
                  "
                >
                  الصلاحيات
                </b-button>
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
  
                  <b-dropdown-item
                    @click="
                      $router.push({
                        name: 'update-role',
                        params: { id: row.item.id },
                      })
                    "
                    v-can="{ slug: $route.meta.slug, action: 'U' }"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">تعديل</span>
                  </b-dropdown-item>
  
                  <b-dropdown-item @click="onDelete(row.item)" v-can="{ slug: $route.meta.slug, action: 'D' }">
                    <feather-icon icon="Trash2Icon" />
                    <span class="align-middle ml-50">حذف</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </data-table>
        </b-card>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BCard,
    BButton,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
  } from "bootstrap-vue";
  import DataTable from "@/components/data-table/index.vue";
  import { mapActions, mapGetters } from "vuex";
  import saveBtn from "@/components/loader-btn";
  import FormModal from "@/components/FormModal.vue";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
//   import MinistryBreadcrumbs from "@/modules/ministry/components/MinistryBreadcrumbs.vue";
  
  export default {
    components: {
      BCard,
      BButton,
      DataTable,
      BFormCheckbox,
      saveBtn,
      BDropdown,
      BDropdownItem,
      FormModal,
    //   MinistryBreadcrumbs,
    },
    data: () => {
      return {
        fields: [
          {
            key: "name",
            label: "الأدوار",
          },
          { key: "actions", label: " " },
        ],
        updateModel: false,
        show: false,
        create: false,
        update: true,
        delete_s: true,
        roleFormSchema: {
          name: {
            component: "FormInput",
            key: "name",
            attrs: {
              label: "اسم الدور",
              rules: "required",
            },
          },
          name_rols: {
            component: "FormSelect",
            key: "role_ids",
            attrs: {
              id: "role_ids",
              ep: "v2/selects/roles",
              reduce: (x) => x.id,
              getOptionLabel: (x) => x.name,
              label: "الصلاحيات",
              rules: "required",
              multiple: true,
            },
          },
        },
      };
    },
    methods: {
      ...mapActions("settings/manageRols", [
        "getParmassins",
        "updateParmassins",
        "deleteRolr",
      ]),
      openPermissions(row) {
        this.getParmassins({
          id: row.item.id,
        });
  
        row.toggleDetails();
      },
      formSubmitEdit(id) {
        this.updateParmassins({
          id: id,
          arrayParim: [
            { show: this.show },
            { create: this.create },
            { update: this.update },
            { delete: this.delete_s },
          ],
        });
      },
      onDelete(item) {
        // console.log(id)
        this.$bvModal
          .msgBoxConfirm("هل أنت متأكد من حذف الدور", {
            title: "تأكيد حذف الدور",
            size: "sm",
            okVariant: "danger",
            okTitle: "تأكيد",
            cancelTitle: "إلغاء",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "جاري الحذف ...",
                icon: "Trash2Icon",
                variant: "info",
              },
            });
            if (value)
              this.deleteRolr({ id: item.id }).then(() => {
                this.$refs.estatesTable.refreshTable();
              });
          });
      },
      onUpdateClicked(form) {
        var toUpdate = {
          name: form.name,
        };
        this.$refs.estateModalUser.init(toUpdate);
        this.updateModel = true;
      },
      onAddClicked() {
        this.$refs.estateModalUser.init({});
        this.updateModel = true;
      },
      onModalConfirmed() {},
    },
    computed: {
      ...mapGetters("settings/manageRols", [
        "parmassins",
        "loadingParmassins",
      ]),
    },
  };
  </script>
  

<!-- <template>
    <div>

      <b-card>
        <h1>{{ $route.name == "add-role" ? "إضافة دور" : "تعديل دور" }}</h1>
        <hr />
        <validation-observer ref="roleUserManage">
          <b-row>
            <b-col cols="4">
              <form-input
                rules="required"
                label="اسم الدور"
                v-model="objectSubmit.role_name"
              />
            </b-col>
          </b-row>
          <b-card no-body class="border mt-1">
            <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon icon="LockIcon" size="18" />
                <span class="align-middle ml-50">الصلاحيات</span>
              </b-card-title>
            </b-card-header>
            <data-table
              ref="estatesTable"
              :fields="fields"
              ep="v2/ministry/selects/name-pages"
              :pagination="false"
              striped
            >
              <template #cell(name)="data">
                <div class="d-flex">
                  <b-form-checkbox
                    @change="addSlug(data.item)"
                    :checked="permissions.includes(data.item.slug)"
                  >
                  </b-form-checkbox>
                  <span>
                    {{ data.item.name }}
                  </span>
                </div>
              </template>
              <template #cell()="data">
                <b-form-checkbox
                  :checked="itemSearsh(data.field.key, data.item.slug)"
                  :disabled="!permissions.includes(data.item.slug)"
                  @change="addPermissions(data)"
                />
              </template>
            </data-table>
          </b-card>
          <save-btn
            variant="success"
            type="submit"
            :loading="Createloading"
            @click="formSubmit()"
          >
          </save-btn>
        </validation-observer>
      </b-card>
    </div>
  </template>
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BCardHeader,
    BCardTitle,
  } from "bootstrap-vue";
  import DataTable from "@/components/data-table/index";
  import FormInput from "@/components/form-input/index.vue";
  import saveBtn from "@/components/loader-btn";
  import { mapActions, mapGetters } from "vuex";
  import { ValidationObserver } from "vee-validate";
  
  export default {
    components: {
      BCard,
      DataTable,
      BRow,
      BCol,
      FormInput,
      BFormCheckbox,
      BCardHeader,
      BCardTitle,
      saveBtn,
      ValidationObserver,
    },
    props: ["id"],
    data: () => {
      return {
        selected: false,
        objectSubmit: {
          role_name: "",
          permissions: [],
        },
        permissions: [],
        fields: [
          {
            key: "name",
            label: "الأدوار",
          },
          {
            key: "show",
            label: "إضهار",
          },
          {
            key: "create",
            label: "إضافة",
          },
          {
            key: "delete",
            label: "حذف",
          },
          {
            key: "update",
            label: "تعديل",
          },
          // {
          //   key: "action",
  
          // },
        ],
      };
    },
    methods: {
      ...mapActions("Ministry/ParmassinsRole", ["createRole", "updateRole"]),
      itemSearsh(key, slug) {
        var idCheck = false;
        if (this.permissions.includes(slug)) {
          this.objectSubmit.permissions.forEach((element) => {
            if (slug == element.name) {
              if (element.sub_permissions.includes(key)) return (idCheck = true);
            }
          });
        }
        return idCheck;
      },
      formSubmit() {
        this.$refs.roleUserManage.validate().then(async (success) => {
          if (success && this.$route.name == "add-role") {
            this.createRole(this.objectSubmit);
          } else {
            this.updateRole({ id: this.id, data: this.objectSubmit });
          }
        });
      },
  
      addSlug(item) {
        if (!this.permissions.includes(item.slug)) {
          this.objectSubmit.permissions.push({
            name: item.slug,
            sub_permissions: [],
          });
          this.permissions.push(item.slug);
        } else {
          this.objectSubmit.permissions = this.objectSubmit.permissions.filter(
            (data) => data.name != item.slug
          );
          this.permissions = this.permissions.filter((data) => data != item.slug);
        }
      },
  
      addPermissions(item) {
        this.objectSubmit.permissions.map((data) => {
          if (data.name == item.item.slug) {
            if (!data.sub_permissions.includes(item.field.key)) {
              data.sub_permissions.push(item.field.key);
            } else {
              data.sub_permissions = data.sub_permissions.filter(
                (data) => data != item.field.key
              );
            }
          }
        });
      },
    },
    computed: {
      ...mapGetters("Ministry/ParmassinsRole", [
        "Createloading",
        "parmassins",
        "loadingParmassins",
      ]),
    },
    watch: {
      selected(val) {},
    },
    created() {
      if (this.id) {
        this.$store
          .dispatch("Ministry/ParmassinsRole/getParmassins", { id: this.id })
          .then(() => {
            this.objectSubmit.role_name = this.parmassins.name;
            this.parmassins.permissions.map((item) => {
              this.objectSubmit.permissions.push({
                name: item.name,
                sub_permissions: item.sub_permissions,
              });
              this.permissions.push(item.name);
            });
          });
      }
    },
  };
  </script> -->